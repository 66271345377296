import { Grid } from "@mui/material";
import React from "react";
import SideBar from "./components/SideBar";
import "../../styles/CourseDetail.css";
import MD from "../../assets/Courses/MS.png";

const CourseDetail = () => {
  return (
    <>
      <Grid container>
        <Grid lg={2} xs={12} md={3}>
          <SideBar />
        </Grid>
        <Grid xs={12} lg={9} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} mt={2}>
              <span className="course-name1">Courses/</span>
              <span className="course-name2">Microsoft Dynamics 365</span>
            </Grid>
            <Grid item xs={12}>
              <div className="course-box">
                <img src={MD} className="c-img" />
              </div>
            </Grid>
            <Grid item>
              <Grid container spacing={5}>
                <Grid item xs={11} md={7} ml={2}>
                  <div className="c-heading">Microsoft Dynamics 365</div>
                  <div className="c-text1">
                    Welcome to our Microsoft Dynamics 365 Course - your gateway
                    to mastering the powerful suite of business applications
                    designed to transform and streamline your organizational
                    processes.
                  </div>
                  <div className="c-text2">About the Course</div>
                  <div className="c-text1">
                    Dive into the world of Microsoft Dynamics 365, where you
                    will gain comprehensive insights into Customer Relationship
                    Management (CRM) and Enterprise Resource Planning (ERP)
                    functionalities. Whether you're a business professional
                    seeking to optimize workflows or an IT enthusiast looking to
                    harness the full potential of Dynamics 365, our course
                    offers in-depth modules covering configuration,
                    customization, and integration, empowering you to drive
                    efficiency and innovation within your organization. Join us
                    on this learning journey to elevate your skills and unlock
                    the full capabilities of Microsoft Dynamics 365.
                  </div>
                  <div className="c-text2">Meet the Tutor</div>
                  <div className="c-text1">Name: Esther Howard</div>
                  <div className="c-text1">Occupation: Python Expert</div>
                  <button className="c-button">Enroll</button>
                </Grid>
                <Grid item xs={11} md={4}>
                  <Grid container spacing={3} className="c-side-box">
                    <Grid item>
                      <div className="c-text3">Beginner Level</div>
                      <div className="c-text4">
                        No previous experience necessary
                      </div>
                      <div className="c-text3">4 weeks in total</div>
                      <div className="c-text4">
                        Tuesdays and Wednesdays ( 21:00 - 22:00 UTC)
                      </div>
                      <div className="c-text3">Course Outline</div>
                      <div className="c-text5">
                        Week 1: Introduction to Microsoft Dynamics 365
                      </div>
                      <div className="c-text4">
                        <ul>
                          <li>
                            Overview of Microsoft Dynamics 365: CRM and ERP
                          </li>
                          <li>Key features and benefits</li>
                          <li>Understanding the Dynamics 365 ecosystem</li>
                        </ul>
                      </div>
                      <div className="c-text5">
                        Week 2: Dynamics 365 CRM Fundamentals
                      </div>
                      <div className="c-text4">
                        <ul>
                          <li>Customer Engagement in Dynamics 365</li>
                          <li>Managing leads, opportunities, and accounts</li>
                          <li>Activities and communication tracking</li>
                        </ul>
                      </div>
                      <div className="c-text5">
                        Week 3: Dynamics 365 ERP Fundamentals
                      </div>
                      <div className="c-text4">
                        {" "}
                        <ul>
                          <li>
                            Introduction to Dynamics 365 Finance and Operations
                          </li>
                          <li>Managing financial data and processes</li>
                          <li>Integrating finance modules with CRM</li>
                        </ul>
                      </div>
                      <div className="c-text5">
                        Week 4: Advanced Features and Integration
                      </div>
                      <div className="c-text4">
                        <ul>
                          <li>Advanced customization and configuration</li>
                          <li>
                            Introduction to Power BI for reporting and analytics
                          </li>
                        </ul>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CourseDetail;
