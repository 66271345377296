import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/SetNavbar";
import Footer from "../components/Footer";
import Email from "../components/Email";
import Webinar from "../components/Webinar";
// import CourseCard from "../components/ProductSlider";
import VideoCall from "../components/VideoCall";
import "../styles/Home.css";
import CourseSelect from "./student/components/CourseSelect";

const Home = () => {
  const nav = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user === "" || user === null) {
      nav("/");
    }
  }, []);
  return (
    <>
      <Header />
      <div className="hb1">
        <div className="welcom-text">Welcome Back!</div>
      </div>
      <CourseSelect />
      <VideoCall />
      <Webinar />
      <Email />
      <Footer />
    </>
  );
};

export default Home;
