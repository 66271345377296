import React from "react";
import { Grid } from "@mui/material";
import SideBar from "./components/InstructorSideBar";
import "../../styles/Course.css";

const TeacherSetting = () => {
  return (
    <>
      <Grid container>
        <Grid lg={2} xs={12} md={3}>
          <SideBar />
        </Grid>
        <Grid xs={12} lg={9} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} className="heading">
              Setting
            </Grid>

            <Grid item></Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TeacherSetting;
