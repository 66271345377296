import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Section1.css";
import F1 from "../assets/UserPage/Frame1.jpg";
import F2 from "../assets/UserPage/Frame2.jpg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #61ED66",
  boxShadow: 24,
  p: 5,
};
const Webinar = () => {
  const nav = useNavigate();
  const handleClickButton = () => {
    nav("/usersignup");
  };
  return (
    <>
      <div>
        <div className="flex-container">
          <div className="flex-item s2-3">Join our Amazing Webinar</div>

          <div className="flex-item s3 s3-2" style={{ color: "#008943" }}>
            Be part of our weekly webinars where amazing lecturers and
            practitioners take you through topics and courses that would blow
            your mind.
          </div>
          <div className="flex-item ">
            <button className="s4" onClick={handleClickButton}>
              Join Now
            </button>
          </div>
        </div>
        <div className="image-f1">
          <img src={F1} className="f1"></img>
          <img src={F2} className="f2"></img>
        </div>
      </div>
    </>
  );
};

export default Webinar;
