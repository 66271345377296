import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../../../styles/SideBar.css";
import C1 from "../../../assets/Courses/C1.png";
import C7 from "../../../assets/Courses/C7.png";
import C6 from "../../../assets/Courses/C6.png";
import C4 from "../../../assets/Courses/C4.png";
import C5 from "../../../assets/Courses/C5.png";
import Avatar from "@mui/material/Avatar";
import InstructorMenu from "./InstructorMenu";

const Menu = [
  {
    img: C1,
    title: "Courses",
    link: "/instcourses",
  },
  {
    img: C7,
    title: "Add Course",
    link: "/addcourse",
  },
  {
    img: C6,
    title: "Account",
    link: "/account",
  },
  {
    img: C4,
    title: "Settings",
    link: "/teachersetting",
  },
];
const InstructorSideBar = () => {
  const nav = useNavigate();
  const [userInfo, setUserInfo] = React.useState();

  useEffect(() => {
    const user = localStorage.getItem("user");
    const data = JSON.parse(user);

    if (user === "" || user === null) {
      nav("/");
    } else {
      setUserInfo(data.firstname + " " + data.lastname);
    }
  }, []);

  const handleLogOut = () => {
    localStorage.removeItem("user");
    nav("/");
  };
  const stringAvatar = () => {
    const name = userInfo || ""; // Set a default value if userInfo is undefined
    const nameParts = name.split(" ");

    if (nameParts.length >= 2) {
      const firstLetter1 = nameParts[0][0].toUpperCase();
      const firstLetter2 = nameParts[1][0].toUpperCase();

      return {
        children: `${firstLetter1}${firstLetter2}`,
      };
    } else {
      // Handle the case where the name does not have a space (not enough parts)
      return {
        children: "NA", // You can set a default value or handle it differently
      };
    }
  };
  return (
    <>
      <InstructorMenu className="Menu" />
      <div className="sidebar-contanior">
        <div className="menu-box">
          {Menu.map((data, index) => (
            <NavLink key={index} className="side-link" to={data.link}>
              <div className="link-box">
                <span className="bar-img">
                  <img src={data.img} alt={data.title} />
                </span>
                <span className="bar-title">{data.title}</span>
              </div>
            </NavLink>
          ))}
        </div>
        <div className="logout" onClick={handleLogOut}>
          <span className="bar-img">
            <img src={C5}></img>
          </span>
          <span className="bar-title">Log out</span>
        </div>
        <div className="user-img">
          <div style={{ textAlign: "center", paddingTop: "5px" }}>
            <Avatar
              {...stringAvatar()}
              sx={{
                width: 50,
                height: 50,
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "5%",
              }}
            />
          </div>
          <div className="user-name">{userInfo}</div>
        </div>
      </div>
    </>
  );
};

export default InstructorSideBar;
