import React, { useRef } from "react";
import Grid from "@mui/material/Grid";

const YourComponent = () => {
  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    // Programmatically trigger click event on the file input
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    // Handle file change here
    const selectedFile = event.target.files[0];
    console.log("Selected file:", selectedFile);
  };

  return (
    <Grid item xs={11} md={9}>
      <div className="upload-box" onClick={handleUploadClick}>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <div>
          <span className="add-upload">Click to upload</span>
          <span className="add-drag">or drag and drop</span>
        </div>
        <div className="add-file">PNG, JPG or GIF (max. 800x400px)</div>
      </div>
    </Grid>
  );
};

export default YourComponent;
