import { Grid } from "@mui/material";
import React from "react";
import SideBar from "./components/SideBar";
import Specialization from "./components/Specialization";
import "../../styles/Course.css";

const Courses = () => {
  return (
    <>
      <Grid container>
        <Grid lg={2} md={3} xs={12}>
          <SideBar />
        </Grid>
        <Grid xs={12} lg={10} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} className="heading">
              Courses
            </Grid>

            <Grid item xs={12}>
              <Specialization />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Courses;
