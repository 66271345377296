import React, { useState } from "react";
import "../styles/Email.css";
import SP from "../assets/HomePage/Megaphone.png";
import { postData } from "../services/Request";
import Swal from "sweetalert2";

const Email = () => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isDisable, setISDisable] = useState(false);

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
    validateEmail(enteredEmail);
  };
  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(inputEmail));
  };
  const saveEmail = () => {
    setISDisable(true);
    if (isValidEmail && email != "") {
      postData("email", { email: email }).then((response) => {
        if (response.status === true) {
          Swal.fire({
            title: "Thanks",
            text: response.message,
            icon: "success",
          });
          window.location.reload();
        } else {
          Swal.fire({
            title: "Oops...",
            text: response.message,
            icon: "info",
          });
          setISDisable(false);
        }
      });
    } else {
      Swal.fire({
        title: "Oops...",
        text: "Invalid email format. Please enter a valid email address.",
        icon: "info",
      });
      setISDisable(false);
    }
  };
  return (
    <>
      <div className="email-container">
        <div className="email-item e1">
          Be Notified of New Videos and Webinars
        </div>

        <div className="email-item e2">
          Become part of a broad community to get in on grooming tips, education
          videos, notifications, and anything and everything that’ll help you
          grow.
          <span>
            <img src={SP} className="email-img"></img>
          </span>
        </div>
        <div className="email-item ">
          <div className="input-email">
            <div style={{ position: "relative" }}>
              <div>
                <input
                  type="email"
                  className="form--input"
                  placeholder="Enter your email address"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                ></input>
              </div>
              <div>
                <button
                  className="email-button"
                  onClick={saveEmail}
                  disabled={isDisable}
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Email;
