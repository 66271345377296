import { Route, Routes } from "react-router-dom";
import Landingpage from "./pages/LandingPage";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";
import SignUp from "./pages/student/UserSignUp";
import LogIn from "./pages/student/UserLogin";
import CoursePreview from "./pages/instructor/CoursePreview";
import InstructorLogIn from "./pages/instructor/InstructorLogin";
import InstructorSignUp from "./pages/instructor/InstructorSignUp";
import InstructorCourses from "./pages/instructor/InstructorCourses";
import AdminLogIn from "./pages/admin/AdminLogin";
import Courses from "./pages/student/Courses";
import MyCourses from "./pages/student/MyCourses";
import CourseDetail from "./pages/student/CourseDetail";
import AddCourse from "./pages/instructor/AddCourse";
import Test from "./pages/test";
import Account from "./pages/instructor/Account";
import Setting from "./pages/student/StudentSetting";
import Certificates from "./pages/student/Certificates";
import StudentsTables from "./pages/admin/StudentsTable";
import InstructorTable from "./pages/admin/InstructorTable";
import TeacherSetting from "./pages/instructor/TeacherSetting";
import TeacherCall from "./pages/instructor/components/TeacherCall";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Landingpage />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/about" element={<About />}></Route>

        {/* Admin  */}
        <Route path="/admin" element={<AdminLogIn />}></Route>
        <Route path="/studentTable" element={<StudentsTables />}></Route>
        <Route path="/InstructorTable" element={<InstructorTable />}></Route>

        {/* Student New Pages */}
        <Route path="/usersignup" element={<SignUp />}></Route>
        <Route path="/userlogin" element={<LogIn />}></Route>
        <Route path="/courses" element={<Courses />}></Route>
        <Route path="/mycourses" element={<MyCourses />}></Route>
        <Route path="/coursedetail" element={<CourseDetail />}></Route>
        <Route path="/studentsetting" element={<Setting />}></Route>

        {/* Instructor New Pages */}
        <Route path="/teachersignup" element={<InstructorSignUp />}></Route>
        <Route path="/teacherlogin" element={<InstructorLogIn />}></Route>
        <Route path="/addcourse" element={<AddCourse />}></Route>
        <Route path="/coursepreview" element={<CoursePreview />}></Route>
        <Route path="/instcourses" element={<InstructorCourses />}></Route>
        <Route path="/account" element={<Account />}></Route>
        <Route path="/teachersetting" element={<TeacherSetting />}></Route>
        <Route path="/teachecall" element={<TeacherCall />}></Route>

        {/* Test */}
        <Route path="/home" element={<Home />}></Route>
        <Route path="/test" element={<Test />}></Route>
        <Route path="/Certificates" element={<Certificates />}></Route>
      </Routes>
    </div>
  );
}

export default App;
