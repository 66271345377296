import React, { useEffect } from "react";
import Header from "../components/SetNavbar";
import Footer from "../components/Footer";
import Section1 from "../components/Section1";
import "../styles/LandingPage.css";
import Email from "../components/Email";
import Webinar from "../components/Webinar";
import Offer from "../components/Offer";
import CourseOffer from "../components/CourseOffer";

const LandingPage = () => {
  useEffect(() => {
    localStorage.setItem("pageUrl", window.location.href);
  }, []);
  return (
    <>
      <Header />
      <Section1 />
      <Offer />
      <CourseOffer />
      <Webinar />
      {/* <div className="box5">
        <div style={{ fontSize: "44px" }}>Our Partners</div>
        <div>
          <img src={P2} alt="p2" className="wave-img"></img>
        </div>
      </div> */}
      <Email />
      <Footer />
    </>
  );
};

export default LandingPage;
