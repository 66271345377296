import React, { useEffect, useState } from "react";
import UserNavbar from "../pages/student/components/UserNavbar";
import InstructorNavebar from "../pages/instructor/components/InstructorNavebar";
import Navbar from "./Navbar";

const SetNavbar = () => {
  const [userType, setUserType] = useState("");

  useEffect(() => {
    const user = localStorage.getItem("user");
    const data = JSON.parse(user);
    if (user === "" || user === null) {
      setUserType("");
    } else {
      setUserType(data.usertype);
    }
  }, []);

  switch (userType) {
    case "Instuctor":
      return <InstructorNavebar />;
    case "Student":
      return <UserNavbar />;
    default:
      return <Navbar />;
  }
};

export default SetNavbar;
