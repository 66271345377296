import Header from "../components/SetNavbar";
import Footer from "../components/Footer";
import "../styles/Contact.css";
import Telephone from "../assets/ContactUs/tel.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Grid,
  Box,
  Button,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";

const ContactUs = () => {
  return (
    <>
      <Header />
      <div className="cb1">
        <div className="contactheading">
          How may we <br />
          help you?
        </div>
        <div>
          <img
            src={Telephone}
            alt="tel"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          ></img>
        </div>
      </div>

      <Grid container spacing={3} justifyContent="center" pt={2} pb={2}>
        <Grid item xs={10}>
          <div
            style={{
              fontSize: "32px",
              fontWeight: "600",
              paddingBottom: "25px",
            }}
          >
            Frequently asked questions
          </div>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Accordion 1</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Accordion 1</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={10}>
          <Box mt={5}>
            <div className="form-heading">Still have a question?</div>
          </Box>
          <Box
            sx={{
              "& > :not(style)": { m: 1, width: { xs: "90%", md: "55ch" } },
            }}
          >
            <TextField label="First Nam" />
            <TextField label="Last Name" />
          </Box>
          <Box
            sx={{
              "& > :not(style)": { m: 1, width: { xs: "90%", md: "55ch" } },
            }}
          >
            <TextField label="Email" type="email" />
            <TextField label="Phone Number" type="number" />
          </Box>
          <Box
            sx={{
              "& > :not(style)": { m: 1, width: { xs: "90%", md: "112ch" } },
            }}
          >
            <TextField label="Message" multiline rows={8} />
          </Box>
          <Box mt={3}>
            <Button variant="contained" color="error">
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Footer />
    </>
  );
};

export default ContactUs;
