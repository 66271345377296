import * as React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Box,
  AppBar,
  Typography,
  Toolbar,
  IconButton,
  Menu,
  Container,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../../assets/Courses/Logo.png";
import "../../../styles/Course.css";
import C1 from "../../../assets/Courses/C1.png";
import C7 from "../../../assets/Courses/C7.png";
import C6 from "../../../assets/Courses/C6.png";
import C4 from "../../../assets/Courses/C4.png";
import C5 from "../../../assets/Courses/C5.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #006D34",
  borderRadius: "8px",
  boxShadow: 24,
  p: 5,
};
const navLinks = [
  { text: "Courses", location: "/instcourses", img: C1 },
  { text: "Add Course", location: "/addcourse", img: C7 },
  { text: "Account", location: "/account", img: C6 },
  { text: "Settings", location: "/teachersetting", img: C4 },
];

const InstructorMenu = () => {
  const nav = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleLogOut = () => {
    localStorage.removeItem("user");
    nav("/");
  };
  return (
    <>
      <AppBar
        position="static"
        sx={{
          bgcolor: "#006D34",
          width: "100%",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography variant="h5">
              <NavLink className="course-links" to="/">
                <img
                  alt="logo"
                  src={Logo}
                  style={{
                    position: "absolute",
                    left: "-25px",
                    top: "12px",
                    width: "100%",
                  }}
                ></img>
              </NavLink>
            </Typography>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none", justifyContent: "end" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="white"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                  width: "200px",
                  color: "#F5FFF2",
                }}
              >
                {navLinks.map((linkInfo, index) => (
                  <MenuItem key={index} onClick={handleCloseNavMenu}>
                    <NavLink className="course-links" to={linkInfo.location}>
                      <div className="link">
                        {" "}
                        <span>
                          <img
                            src={linkInfo.img}
                            style={{ width: "15px", marginRight: "5px" }}
                          ></img>
                        </span>
                        {linkInfo.text}
                      </div>
                    </NavLink>
                  </MenuItem>
                ))}
                <div
                  onClick={handleLogOut}
                  className="link"
                  style={{ marginLeft: "14px", marginTop: "20px" }}
                >
                  <span className="">
                    <img
                      src={C5}
                      style={{ width: "15px", marginRight: "5px" }}
                    ></img>
                  </span>
                  <span className="course-links">Log out</span>
                </div>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};
export default InstructorMenu;
