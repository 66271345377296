import React from "react";
import { Grid } from "@mui/material";
import SideBar from "./components/InstructorSideBar";
import CourseByInstructor from "./components/CourseByInstructor";
import "../../styles/Course.css";

const InstructorCourses = () => {
  return (
    <>
      <Grid container>
        <Grid lg={2} xs={12} md={3}>
          <SideBar />
        </Grid>
        <Grid xs={12} lg={10} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} className="heading">
              Courses
            </Grid>

            <Grid item sx={12}>
              <CourseByInstructor />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InstructorCourses;
