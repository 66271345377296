import React, { useState, useEffect } from "react";
import { Checkbox, FormControlLabel, FormGroup, Button } from "@mui/material";
import MD from "../../../assets/Poster/MD.jpg";
import AI from "../../../assets/Poster/AI.jpg";
import "../../../styles/CourseOffer.css";
import { sendEmail, postData } from "../../../services/Request";
import Swal from "sweetalert2";

const CourseSelect = () => {
  const [course1, setCourse1] = useState("");
  const [course2, setCourse2] = useState("");
  const [MSLevels, setMSLevels] = useState([]);
  const [AILevels, setAILevels] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [loader, setLoder] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem("user");
    const data = JSON.parse(user);
    setUserInfo(data);
  }, []);

  const handleCheckbox1 = (courseName, level) => {
    setCourse1(courseName);
    if (MSLevels.includes(level)) {
      setMSLevels(MSLevels.filter((MSLevels) => MSLevels !== level));
    } else {
      setMSLevels([...MSLevels, level]);
    }
  };
  const handleCheckbox2 = (courseName, level) => {
    setCourse2(courseName);
    if (AILevels.includes(level)) {
      setAILevels(AILevels.filter((AILevels) => AILevels !== level));
    } else {
      setAILevels([...AILevels, level]);
    }
  };

  const mailStudent = {
    fullName: userInfo.firstname + " " + userInfo.lastname,
    email: userInfo.email,
  };

  const addMSCourse = () => {
    setLoder(true);
    if (MSLevels.length == 0) {
      Swal.fire({
        title: "Oops...",
        text: "Please Select any Option",
        icon: "info",
      });
      setLoder(false);
    } else {
      sendEmail("/mail.php", mailStudent);
      const applyCourse = {
        userId: userInfo.userId,
        courseName: course1,
        courseLevel: MSLevels.join(", "),
      };
      postData("selectcourse", applyCourse).then((response) => {
        if (response.status === true) {
          Swal.fire({
            title: "Congratulations",
            text: "You Enroll in this Course",
            icon: "success",
          });
          window.open(
            "https://chat.whatsapp.com/L0362sTsqwiHrv23KrMscn",
            "_self"
          );
        } else {
          Swal.fire({
            title: "Sorry",
            text: "You Cannot Enroll in this Course",
            icon: "error",
          });
          setLoder(false);
        }
      });
    }
  };
  const addAICourse = () => {
    setLoder(true);
    if (AILevels.length == 0) {
      Swal.fire({
        title: "Oops...",
        text: "Please Select any Option",
        icon: "info",
      });
      setLoder(false);
    } else {
      sendEmail("/mail.php", mailStudent);
      const applyCourse = {
        userId: userInfo.userId,
        courseName: course2,
        courseLevel: AILevels.join(", "),
      };
      postData("selectcourse", applyCourse).then((response) => {
        if (response.status === true) {
          Swal.fire({
            title: "Congratulations",
            text: "You Enroll in this Course",
            icon: "success",
          });
          window.open(
            "https://chat.whatsapp.com/L0362sTsqwiHrv23KrMscn",
            "_self"
          );
        } else {
          Swal.fire({
            title: "Sorry",
            text: "You Cannot Enroll in this Course",
            icon: "error",
          });
          setLoder(false);
        }
      });
    }
  };

  return (
    <>
      <div className="cours-box">
        <div className="cours-card">
          <div>
            <img
              src={MD}
              alt="1"
              style={{ width: "280px", borderRadius: "8px 8px 0 0" }}
            ></img>
          </div>
          <div className="course1-name">Microsoft Dynamics 365</div>
          <div style={{ marginLeft: "10px", marginTop: "10px" }}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={MSLevels.includes("Beginner")} />}
                disabled={
                  MSLevels.length == 2 && !MSLevels.includes("Beginner")
                }
                onChange={() => handleCheckbox1("Microsoft", "Beginner")}
                label="Beginner"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={MSLevels.includes("Intermediate")} />
                }
                disabled={
                  MSLevels.length == 2 && !MSLevels.includes("Intermediate")
                }
                onChange={() => handleCheckbox1("Microsoft", "Intermediate")}
                label="Intermediate"
              />
              <FormControlLabel
                control={<Checkbox checked={MSLevels.includes("Advanced")} />}
                disabled={
                  MSLevels.length == 2 && !MSLevels.includes("Advanced")
                }
                onChange={() => handleCheckbox1("Microsoft", "Advanced")}
                label="Advanced"
              />
            </FormGroup>
          </div>
          <div>
            {loader === false ? (
              <button className="btn-enroll" onClick={() => addMSCourse()}>
                Enroll
              </button>
            ) : (
              <button className="btn-enroll2">Enroll</button>
            )}
          </div>
        </div>
        <div className="cours-card">
          <div>
            <img
              src={AI}
              alt="1"
              style={{ width: "280px", borderRadius: "8px 8px 0 0" }}
            ></img>
          </div>
          <div className="course1-name">Artifical Intelligence</div>
          <div style={{ marginLeft: "10px", marginTop: "10px" }}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={AILevels.includes("Beginner")} />}
                disabled={
                  AILevels.length == 2 && !AILevels.includes("Beginner")
                }
                onChange={() => handleCheckbox2("AI", "Beginner")}
                label="Beginner"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={AILevels.includes("Intermediate")} />
                }
                disabled={
                  AILevels.length == 2 && !AILevels.includes("Intermediate")
                }
                onChange={() => handleCheckbox2("AI", "Intermediate")}
                label="Intermediate"
              />
              <FormControlLabel
                control={<Checkbox checked={AILevels.includes("Advanced")} />}
                disabled={
                  AILevels.length == 2 && !AILevels.includes("Advanced")
                }
                onChange={() => handleCheckbox2("AI", "Advanced")}
                label="Advanced"
              />
            </FormGroup>
          </div>
          <div>
            {loader === false ? (
              <button className="btn-enroll" onClick={() => addAICourse()}>
                Enroll
              </button>
            ) : (
              <button className="btn-enroll2">Enroll</button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseSelect;
