import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import "../../../styles/Specialization.css";
import CircularProgress from "@mui/material/CircularProgress";
import { postData } from "../../../services/Request";
import Swal from "sweetalert2";

const CourseByInstructor = () => {
  const nav = useNavigate();
  const [loader, setLoder] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [userCourses, setUserCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState();

  useEffect(() => {
    const page = localStorage.getItem("pageUrl");
    setUrl(page);
    const user = localStorage.getItem("user");
    if (user === "" || user === null) {
      nav("/");
    } else {
      const data = JSON.parse(user);
      setUserInfo(data);
      postData("getcourse", {
        userId: data.userId,
      })
        .then((response) => {
          if (response.courses.length == 0) {
            nav("/addcourse");
          }
          setUserCourses(response.courses);
        })
        .finally(() => setLoading(false));
    }
  }, []);

  const handleClickButton = (course) => {
    if (userInfo.status == 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You have no Permission from Admin!",
      });
    } else {
      const newPage = `${url}teachecall?courseName=${course}`;
      window.open(newPage, "_blank");
    }
  };
  const filteredCourses = userCourses.filter((course) =>
    course.courseName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div style={{ position: "relative", minHeight: "100vh" }}>
        {loading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent white background
              backdropFilter: "blur(8px)", // Blurred background
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <Grid container justifyContent="center">
          <Grid item xs={11}>
            <Grid container spacing={5} className="sp-box">
              <Grid item xs={12}>
                <div className="search-item">
                  <div className="input-search">
                    <div style={{ position: "relative" }}>
                      <div>
                        <input
                          type="text"
                          className="search-input"
                          placeholder="search for a course"
                          id="text"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        ></input>
                      </div>
                      <div>
                        <button className="search-button">Search</button>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  {filteredCourses.map((product, index) => (
                    <Grid item xs={12} md={6} lg={4} key={index}>
                      <div className="img-box">
                        <img
                          src={product.image}
                          className="sp-img"
                          style={{ borderRadius: "5px" }}
                        />
                      </div>
                      <div className="level-corses">
                        <span className="level">{product.courseLevel}</span>
                      </div>
                      <div className="sp-title">{product.courseName}</div>
                      <div className="sp-text">{product.description}</div>

                      {loader === false ? (
                        <button
                          className="sp-button"
                          onClick={() => handleClickButton(product.courseName)}
                        >
                          Start Live class
                        </button>
                      ) : (
                        <button className="sp-button">
                          <CircularProgress />
                        </button>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default CourseByInstructor;
