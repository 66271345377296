import Header from "../components/SetNavbar";
import Footer from "../components/Footer";
import Pic from "../assets/AboutUs/girl2.png";
import "../styles/About.css";
import { Grid } from "@mui/material";

const About = () => {
  return (
    <>
      <Header />
      <div className="about-box1">
        <div className="about-text">
          Taking E-Learning <br />
          To A New Level
        </div>
        <div className="">
          <img src={Pic} alt="pic"></img>
        </div>
      </div>
      <div className="about-box2">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={10}>
            <div className="about-heading">About Us</div>
            <div className="about-text1">
              We are a leading software development training company that offers
              comprehensive and industry-focused training programs in a wide
              range of technologies and methodologies. Our mission is to equip
              individuals with the skills and knowledge needed to succeed in the
              rapidly evolving field of software development.
            </div>
            <div className="about-text2">
              <br />
              Our training programs cover a diverse set of topics, including:
            </div>
            <div className="about-text3">
              <ol>
                <li>
                  Artificial Intelligence (AI): Our AI training program covers
                  the fundamentals of AI, machine learning, deep learning, and
                  natural language processing. Students learn how to develop AI
                  models and algorithms, and apply them to solve real-world
                  problems.
                </li>
                <li style={{ marginTop: "5px" }}>
                  Microsoft Dynamics 365: Our Microsoft Dynamics 365 training
                  program provides in-depth knowledge of the Dynamics 365
                  platform, including modules such as sales, customer service,
                  marketing, and finance. Students learn how to customize and
                  configure Dynamics 365 to meet business requirements.
                </li>
                <li style={{ marginTop: "5px" }}>
                  JavaScript: JavaScript is a widely used programming language
                  for web development. Our JavaScript training program covers
                  the core concepts of JavaScript, as well as frameworks and
                  libraries such as React and Angular. Students learn how to
                  build interactive and dynamic web applications.
                </li>
                <li style={{ marginTop: "5px" }}>
                  Mobile / Web App Development: Our mobile and web app
                  development training program covers the latest technologies
                  and frameworks for building mobile and web applications.
                  Students learn how to develop responsive and user-friendly
                  apps using platforms such as Android, iOS, and React Native.
                </li>
                <li style={{ marginTop: "5px" }}>
                  Cyber Security: Cybersecurity is a critical aspect of software
                  development. Our cybersecurity training program covers topics
                  such as network security, application security, ethical
                  hacking, and security testing. Students learn how to identify
                  and mitigate security threats to ensure the integrity of
                  software systems.
                </li>
                <li style={{ marginTop: "5px" }}>
                  Agile Methodology: Agile methodology is widely adopted in
                  software development projects for its iterative and
                  collaborative approach. Our Agile training program covers the
                  principles and practices of Agile, including Scrum and Kanban.
                  Students learn how to effectively manage projects and deliver
                  high-quality software in an Agile environment.
                </li>
              </ol>
            </div>
            <div className="about-text2">
              In addition to these topics, we offer training programs in various
              other areas, including cloud computing, data science, DevOps, and
              more. Our training programs are designed and delivered by industry
              experts with extensive practical experience, ensuring that our
              students receive the most up-to-date and relevant training.
              <br />
              <br />
              We understand that everyone has different learning preferences and
              schedules. That's why we offer flexible training options,
              including in-person and online classes, to accommodate the needs
              of our students. Our training programs are designed to be hands-on
              and practical, allowing students to apply their knowledge through
              real-world projects and exercises.
              <br />
              <br />
              By choosing our software development training company, you can be
              confident that you will receive top-notch training that prepares
              you for success in the software industry. Our goal is to help you
              build a strong foundation of skills and knowledge, and empower you
              to achieve your career aspirations. Join us and embark on a
              rewarding journey of learning and growth in the field of software
              development.
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
};

export default About;
