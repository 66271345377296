import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserList from "./UserList";
import Header from "./Header";

const StudentsTables = () => {
  const nav = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("user");
    const data = JSON.parse(user);
    if (user === "" || user === null || data.usertype != "Admin") {
      nav("/admin");
    }
  }, []);

  return (
    <>
      <Header />
      <UserList />
    </>
  );
};

export default StudentsTables;
