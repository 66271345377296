import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, TextField, Button, MenuItem } from "@mui/material";
import SideBar from "./components/InstructorSideBar";
import "../../styles/AddCourse.css";
import Uplaod from "../../assets/Courses/upload-icon.png";
import { uploadFileService } from "../../services/Request";

const Courselevel = [
  {
    value: "beginner",
    label: "Beginner",
  },
  {
    value: "intermediate",
    label: "Intermediate",
  },
  {
    value: "advance",
    label: "Advance",
  },
];

const AddCourse = () => {
  const nav = useNavigate();
  const fileInputRef = useRef(null);
  const [level, setLevel] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [instructorId, setInstructorId] = useState("");
  const [instructorName, setInstructorName] = useState("");

  useEffect(() => {
    const user = localStorage.getItem("user");
    const data = JSON.parse(user);
    setInstructorName(data.firstname + " " + data.lastname);
    setInstructorId(data.userId);
  }, []);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    let file = e.target.files[0];

    if (file.size / 1024 / 1024 > 1) {
      alert("File size exceeds 1 MB");
    } else {
      uploadFileService(file).then((response) => {
        console.log(response);
        if (response.status === true) {
          setImageUrl(response.location);
        } else {
          alert("Error upload picture");
        }
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    var details = {
      instructorId: instructorId,
      instructorName: instructorName,
      courseName: e.target.courseName.value,
      startDate: e.target.startDate.value,
      endDate: e.target.endDate.value,
      courseLevel: level,
      description: e.target.description.value,
      courseOutline: e.target.courseOutline.value,
      image: imageUrl,
    };

    localStorage.setItem("addcourse", JSON.stringify(details));
    nav("/coursepreview");
  };

  return (
    <>
      <Grid container>
        <Grid lg={2} xs={12} md={3}>
          <SideBar />
        </Grid>
        <Grid xs={12} lg={9} md={8} ml={3}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} className="heading1">
                Add Course
              </Grid>

              <Grid item xs={11} md={9}>
                <div className="upload-box" onClick={handleUploadClick}>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <div>
                    <img src={Uplaod}></img>
                  </div>
                  <div>
                    <span className="add-upload">Click to upload</span>
                    <spna className="add-drag">or drag and drop</spna>
                  </div>
                  <div className="add-file">
                    PNG, JPG or GIF (max. 800x400px)
                  </div>

                  {imageUrl && (
                    <div>
                      <img
                        src={imageUrl}
                        alt="Selected"
                        style={{ maxWidth: "250px" }}
                      />
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={11} md={9}>
                <TextField
                  color="success"
                  margin="normal"
                  required
                  fullWidth
                  name="courseName"
                  label="Course Name"
                  type="text"
                  id="courseName"
                  focused
                  //   value={courseName}
                />
              </Grid>
              <Grid item xs={11} md={9}>
                <TextField
                  color="success"
                  required
                  fullWidth
                  id="startDate"
                  label="Start Date"
                  name="startDate"
                  type="date"
                  focused
                />
              </Grid>
              <Grid item xs={11} md={9}>
                <TextField
                  color="success"
                  required
                  fullWidth
                  id="endDate"
                  label="End Date"
                  name="endDate"
                  type="date"
                  focused
                />
              </Grid>
              <Grid item xs={11} md={9}>
                <TextField
                  color="success"
                  id="level"
                  select
                  fullWidth
                  name="courseLevel"
                  label="Course Difficulty Level"
                  placeholder="Choose the level of difficulty of this course"
                  required
                  focused
                >
                  {Courselevel.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      onClick={() => {
                        setLevel(option.value);
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={11} md={9}>
                <TextField
                  color="success"
                  required
                  fullWidth
                  name="description"
                  label="Course Description"
                  placeholder="Give a brief description of this course"
                  type="text"
                  id="description"
                  multiline
                  rows={3}
                  focused
                />
              </Grid>
              <Grid item xs={11} md={9}>
                <TextField
                  color="success"
                  required
                  fullWidth
                  name="courseOutline"
                  label="Course Outlines"
                  placeholder="Give a brief description of this course"
                  type="text"
                  id="courseOutline"
                  multiline
                  rows={3}
                  focused
                />
              </Grid>
              <Grid item xs={11} md={9}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="success"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Preview
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default AddCourse;
