import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import "../../../styles/SideBar.css";
import C1 from "../../../assets/Courses/C1.png";
import C2 from "../../../assets/Courses/C2.png";
import C3 from "../../../assets/Courses/C3.png";
import C4 from "../../../assets/Courses/C4.png";
import C5 from "../../../assets/Courses/C5.png";
import CourseMenu from "./CourseMenu";

const Menu = [
  {
    img: C1,
    title: "My Courses",
    link: "/mycourses",
  },
  {
    img: C2,
    title: "Courses",
    link: "/courses",
  },
  {
    img: C3,
    title: "Certificates",
    link: "/certificates",
  },
  {
    img: C4,
    title: "Settings",
    link: "/studentsetting",
  },
];
const SideBar = () => {
  const nav = useNavigate();
  const [userInfo, setUserInfo] = React.useState();

  useEffect(() => {
    const user = localStorage.getItem("user");
    const data = JSON.parse(user);

    if (user === "" || user === null) {
      nav("/");
    } else {
      setUserInfo(data.firstname + " " + data.lastname);
    }
  }, []);

  const handleLogOut = () => {
    localStorage.removeItem("user");
    nav("/");
  };
  const stringAvatar = () => {
    const name = userInfo || ""; // Set a default value if userInfo is undefined
    const nameParts = name.split(" ");

    if (nameParts.length >= 2) {
      const firstLetter1 = nameParts[0][0].toUpperCase();
      const firstLetter2 = nameParts[1][0].toUpperCase();

      return {
        children: `${firstLetter1}${firstLetter2}`,
      };
    } else {
      return {
        children: "NA",
      };
    }
  };

  return (
    <>
      <CourseMenu className="Menu" />
      <div className="sidebar-contanior">
        <div className="menu-box">
          {Menu.map((data, index) => (
            <NavLink className="side-link" to={data.link}>
              <div key={index} className="link-box">
                <span className="bar-img">
                  <img src={data.img}></img>
                </span>
                <span className="bar-title">{data.title}</span>
              </div>
            </NavLink>
          ))}
        </div>
        <div className="logout" onClick={handleLogOut}>
          <span className="bar-img">
            <img src={C5}></img>
          </span>
          <span className="bar-title">Log out</span>
        </div>
        <div className="user-img">
          <div style={{ textAlign: "center", paddingTop: "5px" }}>
            <Avatar
              {...stringAvatar()}
              sx={{
                width: 50,
                height: 50,
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "30px",
              }}
            />
            {/* <Avatar
              alt={userInfo}
              src={El}
              sx={{
                width: 80,
                height: 80,
                marginLeft: "auto",
                marginRight: "auto",
                paddingTop: "30px",
              }}
            >
              {userInfo}
            </Avatar> */}
          </div>
          <div className="user-name">{userInfo}</div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
