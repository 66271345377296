import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Container,
  Box,
  Link,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import { postData } from "../../services/Request";
import CircularProgress from "@mui/material/CircularProgress";
import S1 from "../../assets/ContactUs/L1.jpg";
import logo from "../../assets/Header/logo3.png";
import "../../styles/SignUp.css";

export default function UserLogIn() {
  const nav = useNavigate();

  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [loader, setLoder] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    var details = {
      email: e.target.email.value,
      password: e.target.password.value,
    };
    if (details.email.trim() === "") {
      setAlertMessage("Input your email");
      setSeverity("warning");
    } else if (details.password.trim() === "") {
      setAlertMessage("Enter your Passwords");
      setSeverity("warning");
    } else {
      setLoder(true);
      setAlertMessage("");
      postData("userlogin", details)
        .then((response) => {
          setLoder(false);
          if (response.status === true) {
            localStorage.setItem("user", JSON.stringify(response.user));
            setAlertMessage("Logging you in......");
            setSeverity("success");
            nav("/courses");
          } else {
            setAlertMessage("Error logging you in");
            setSeverity("error");
          }
        })
        .catch((error) => {
          setLoder(false);
          setAlertMessage(
            "Error logging you in, please check your account is activated"
          );
          setSeverity("error");
        });
    }
  };

  return (
    <div className="SignUp-container">
      <div style={{ padding: "25px" }}>
        <div className="img-mobile">
          <img src={logo}></img>
        </div>
        <div className="img-box">
          <img
            src={S1}
            className="signup-img"
            style={{ borderRadius: "22px" }}
          ></img>
        </div>
        <div className="form-box">
          <Container component="main" maxWidth="xs">
            {alertMessage && (
              <Alert variant="filled" severity={severity}>
                {alertMessage}
              </Alert>
            )}
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "700",
                  textAlign: "center",
                  paddingTop: "25px",
                }}
              >
                Log In
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", paddingTop: "5px" }}
              >
                Access your personalized tech learning experience with a
                click—log in now!
              </Typography>

              <Box
                sx={{
                  marginTop: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "32px", fontWeight: "700" }}>
                  Welcome Back Student
                </Typography>

                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  />

                  {loader === false && (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 3,
                        mb: 2,
                        backgroundColor: "#006D34",
                        "&:hover": {
                          backgroundColor: "#45a049",
                        },
                      }}
                    >
                      Log In
                    </Button>
                  )}
                  {loader === true && (
                    <Button fullWidth variant="outlined">
                      <CircularProgress />
                    </Button>
                  )}
                  <Grid container justifyContent="center" paddingBottom={2}>
                    <Grid item>
                      Don’t have an Account?
                      <Link
                        href="/usersignup"
                        variant="body2"
                        style={{ color: "#006D34" }}
                      >
                        {" Sign Up"}
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </div>
      </div>
    </div>
  );
}
