import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import SideBar from "./components/InstructorSideBar";
import { postData } from "../../services/Request";
import "../../styles/AddCourse.css";
import CircularProgress from "@mui/material/CircularProgress";

const CoursePreview = () => {
  const nav = useNavigate();
  const [aboutCourse, setAboutCourse] = useState([]);
  const [loader, setLoder] = useState(false);

  useEffect(() => {
    const course = localStorage.getItem("addcourse");
    const data = JSON.parse(course);
    setAboutCourse(data);
  }, []);

  const handleUploadCourse = () => {
    postData("addcourse", aboutCourse)
      .then((response) => {
        console.log(response);
        setLoder(false);
        if (response.status === true) {
          nav("/instcourses");
        } else {
          alert("Error adding course ");
        }
      })
      .catch((error) => {
        setLoder(false);
        alert("Error adding course , please check your account is activated");
      });
  };
  return (
    <>
      <Grid container>
        <Grid lg={2} xs={12} md={3}>
          <SideBar />
        </Grid>
        <Grid xs={12} lg={9} ml={3} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} className="heading1">
              Add Course
            </Grid>
            <Grid item xs={11} md={10}>
              <div className="prview-box">
                <div>
                  <img src={aboutCourse.image} style={{ width: "250px" }}></img>
                </div>
                <div className="p-label">Course Name</div>
                <div className="p-text">{aboutCourse.courseName}</div>
                <div className="p-label">Start Date</div>
                <div className="p-text">{aboutCourse.startDate}</div>
                <div className="p-label">End Date</div>
                <div className="p-text">{aboutCourse.endDate}</div>
                <div className="p-label">Difficulty Level</div>
                <div className="p-text">{aboutCourse.courseLevel}</div>
                <div className="p-label">Course Description</div>
                <div className="p-text">{aboutCourse.description}</div>
                <div className="p-label">Course Outline</div>
                <div className="p-text">{aboutCourse.courseOutline}</div>
              </div>
            </Grid>

            <Grid item xs={11} md={10}>
              {loader === true ? (
                <Button fullWidth variant="outlined" color="success">
                  <CircularProgress />
                </Button>
              ) : (
                <Button
                  onClick={handleUploadCourse}
                  fullWidth
                  variant="contained"
                  color="success"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Add Course
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CoursePreview;
