import React from "react";
import Grid from "@mui/material/Grid";
import I1 from "../assets/Poster/1.png";
import I2 from "../assets/Poster/2.png";
import I3 from "../assets/Poster/3.png";
import I4 from "../assets/Poster/4.png";
import I5 from "../assets/Poster/5.png";
import I6 from "../assets/Poster/6.png";
import "../styles/CourseOffer.css";

const products = [
  {
    img: I1,
    label: "Live Video Sessions",
    text: "Engage in real-time, one-on-one video calls with experienced tutors who guide you through the intricacies of various tech skills. Benefit from personalized attention and instant feedback to accelerate your learning.",
  },
  {
    img: I2,
    label: "Flexible Scheduling",
    text: "Tailor your learning experience to fit your schedule. Our platform offers flexibility, allowing you to book sessions at times that best suit your lifestyle, ensuring that education seamlessly integrates into your routine.",
  },
  {
    img: I3,
    label: "Hands-On Practice",
    text: "Reinforce theoretical knowledge with hands-on, practical exercises. Tutors provide live demonstrations, share screens, and offer real-time coding exercises to enhance your understanding and application of tech concepts.",
  },
  {
    img: I4,
    label: "Customized Learning Paths",
    text: "Whether you're a beginner or looking to advance your skills, our platform offers personalized learning paths. Tutors work with you to understand your goals and create a curriculum that aligns with your unique learning objectives.",
  },
  {
    img: I5,
    label: "Collaborative Learning Community",
    text: "Connect with like-minded learners in our collaborative community. Exchange ideas, seek assistance, and participate in group projects to enhance your collaborative and teamwork skills in a supportive online environment.",
  },
  {
    img: I6,
    label: "Progress Tracking and Assessments",
    text: "Monitor your progress with detailed performance analytics. Receive constructive feedback from tutors, track your achievements, and identify areas for improvement through regular assessments, ensuring a clear understanding of your development.",
  },
];
const Offer = () => {
  return (
    <>
      <div className="box-c">
        <div className="box-text">
          <div className="haed-text">What we offer</div>
        </div>

        <Grid container spacing={6} padding={5}>
          {products.map((product, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <div
                style={{
                  border: "1px solid",
                  borderRadius: "10px",
                  backgroundColor: "#F5FFFA",
                  height: "100%",
                }}
              >
                <div className="cf-img">
                  <img src={product.img} alt="1"></img>
                </div>
                <div className="label">{product.label}</div>
                <div className="text">{product.text}</div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};

export default Offer;
