import React, { useState, useEffect } from "react";
import "../../../styles/Call.css";
import Call from "../../../assets/Instructor/video-call.png";
import { useLocation } from "react-router-dom";

const TeacherCall = () => {
  const location = useLocation();
  const [iframeSrc, setIframeSrc] = useState("");
  const [callStarted, setCallStarted] = useState(false);
  const [userType, setUserType] = useState(false);
  const [subjectName, setSubjectName] = useState("");

  const handleStartCall = () => {
    setCallStarted(true);
    setIframeSrc(`https://meet.covid19telemedics.com/techub%20and%20react`);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const courseName = params.get("courseName");
    setSubjectName(courseName.toUpperCase());
    const user = localStorage.getItem("user");
    const data = JSON.parse(user);
    if (data.usertype === "Student") {
      setUserType(true);
    }
  }, []);

  const handleEndCall = () => {
    setCallStarted(false);
  };
  return (
    <>
      <div style={{ paddingBottom: "8px", position: "relative" }}>
        <div className="call-heading">
          LIVE INSTRUCTOR {subjectName} SESSION
        </div>
        <div style={{ height: "auto" }}>
          {callStarted ? (
            <iframe
              title="Embedded Content"
              width="100%"
              height="600"
              src={iframeSrc}
              allow="microphone; camera"
              sandbox="allow-same-origin allow-scripts"
            />
          ) : (
            <div
              style={{
                height: "auto",
                textAlign: "center",
                paddingTop: "5px",
              }}
            >
              <img src={Call} alt="video" className="call-img"></img>
            </div>
          )}
        </div>

        <div className="button-box">
          {userType ? (
            <>
              {callStarted ? (
                <button className="call-end" onClick={handleEndCall}>
                  End Call
                </button>
              ) : (
                <button className="call-start" onClick={handleStartCall}>
                  Join Live Call
                </button>
              )}
            </>
          ) : (
            <>
              {callStarted ? (
                <button className="call-end" onClick={handleEndCall}>
                  End Call
                </button>
              ) : (
                <button className="call-start" onClick={handleStartCall}>
                  Start Call
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default TeacherCall;
