import React, { useState, useEffect } from "react";
import "../styles/Call.css";
import Call from "../assets/Instructor/video-call.png";

const VideoCall = () => {
  const [iframeSrc, setIframeSrc] = useState("");
  const [callStarted, setCallStarted] = useState(false);
  const [userType, setUserType] = useState(false);
  const [userStatus, setUserStatus] = useState();

  const handleStartCall = () => {
    setCallStarted(true);
    setIframeSrc(`https://meet.covid19telemedics.com/techub%20and%20react`);
  };
  const handleEndCall = () => {
    setCallStarted(false);
  };
  useEffect(() => {
    const user = localStorage.getItem("user");
    const data = JSON.parse(user);
    setUserStatus(data.status);
    if (data.usertype === "Student") {
      setUserType(true);
    }
  }, []);

  return (
    <>
      <div style={{ paddingBottom: "8px", position: "relative" }}>
        <div className="call-heading">LIVE INSTRUCTOR LED SESSION</div>
        <div style={{ height: "auto" }}>
          {callStarted ? (
            <iframe
              title="Embedded Content"
              width="100%"
              height="350"
              src={iframeSrc}
              allow="microphone; camera"
              sandbox="allow-same-origin allow-scripts"
            />
          ) : (
            <div
              style={{
                height: "auto",
                textAlign: "center",
                paddingTop: "5px",
              }}
            >
              <img src={Call} alt="video" className="call-img"></img>
            </div>
          )}
        </div>
        {userStatus === 1 ? (
          <>
            <div className="button-box">
              {userType ? (
                <button className="call-start" onClick={handleStartCall}>
                  Join Live Call
                </button>
              ) : (
                <>
                  <button className="call-start" onClick={handleStartCall}>
                    Start Call
                  </button>
                  <button className="call-end" onClick={handleEndCall}>
                    End Call
                  </button>
                </>
              )}
            </div>
          </>
        ) : (
          <div className="pay-pyment">Not Activated</div>
        )}
      </div>
    </>
  );
};

export default VideoCall;
