import * as React from "react";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import {
  Grid,
  Box,
  Button,
  AppBar,
  Typography,
  Toolbar,
  IconButton,
  Menu,
  Container,
  MenuItem,
  Modal,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../assets/Header/logo2.png";
import "./../styles/Header.css";

const navLinks = [
  { text: "Home", location: "/" },
  { text: "Courses", location: "usersignup" },
  { text: "About", location: "/about" },
  { text: "Contact", location: "/contact" },
];

const ResponsiveAppBar = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 250 : 400,
    bgcolor: "background.paper",
    border: "2px solid #006D34",
    borderRadius: "8px",
    boxShadow: 24,
    p: isMobile ? 2 : 5,
  };
  const [open, setOpen] = React.useState(false);
  const [openSign, setOpenSign] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const signupClose = () => setOpenSign(false);
  const signupOpen = () => setOpenSign(true);

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          bgcolor: "white",
          boxShadow: "none",
          borderBottom: "1px solid #d8d8d8",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                mt: 1,
              }}
            >
              <img alt="logo" src={Logo}></img>
            </Typography>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none", justifyContent: "end" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="black"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                  width: "200px",
                  color: "#61DE86",
                }}
              >
                {navLinks.map((linkInfo, index) => (
                  <MenuItem key={index} onClick={handleCloseNavMenu}>
                    <NavLink className="nav-links" to={linkInfo.location}>
                      <div className="link">{linkInfo.text}</div>
                    </NavLink>
                  </MenuItem>
                ))}
                <div style={{ padding: "5px" }}>
                  <button
                    variant="outlined"
                    className="login2-button"
                    onClick={handleOpen}
                  >
                    Login
                  </button>
                </div>
                <div style={{ padding: "5px" }}>
                  <button
                    variant="contained"
                    className="signup2-button"
                    onClick={signupOpen}
                  >
                    Sign Up
                  </button>
                </div>
              </Menu>
            </Box>

            <Box
              sx={{
                flexGrow: 2,
                justifyContent: "end",
                display: { xs: "none", md: "flex" },
              }}
            >
              {navLinks.map((linkInfo, index) => (
                <Button
                  key={index}
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: "#171717",
                    display: "block",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                >
                  <NavLink className="nav-links" to={linkInfo.location}>
                    <div className="link">{linkInfo.text}</div>
                  </NavLink>
                </Button>
              ))}
            </Box>

            <Box
              sx={{
                flexGrow: { xs: 5, md: 1 },
                display: { xs: "none", md: "flex" },
              }}
            >
              <Grid container spacing={2} justifyContent="center">
                <Grid item md={6} hidden={{ xs: true }}>
                  {" "}
                </Grid>
                <Grid item xs={6} md={3}>
                  <button
                    variant="outlined"
                    className="login-button"
                    onClick={handleOpen}
                  >
                    Login
                  </button>
                </Grid>
                <Grid item xs={6} md={3}>
                  <button
                    variant="contained"
                    className="signup-button"
                    onClick={signupOpen}
                  >
                    Sign Up
                  </button>
                </Grid>
              </Grid>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container>
              <Grid item xs={12} lg={6} pt={2}>
                <NavLink className="nav-links" to="/userlogin">
                  <Button
                    variant="contained"
                    sx={{
                      color: "white",
                      border: "1px solid #006D34",
                      backgroundColor: "#006D34",
                    }}
                  >
                    Student Login
                  </Button>
                </NavLink>
              </Grid>
              <Grid item xs={12} lg={6} pt={2}>
                <NavLink className="nav-links" to="/teacherlogin">
                  <Button
                    variant="contained"
                    sx={{
                      color: "white",
                      border: "1px solid #006D34",
                      backgroundColor: "#006D34",
                    }}
                  >
                    Instructor Login
                  </Button>
                </NavLink>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </div>
      <div>
        <Modal
          open={openSign}
          onClose={signupClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container>
              <Grid item xxs={12} lg={6} pt={2}>
                <NavLink className="nav-links" to="/usersignup">
                  <Button
                    variant="contained"
                    sx={{
                      color: "white",
                      border: "1px solid #006D34",
                      backgroundColor: "#006D34",
                    }}
                  >
                    Student SignUp
                  </Button>
                </NavLink>
              </Grid>
              <Grid item xxs={12} lg={6} pt={2}>
                <NavLink className="nav-links" to="/teachersignup">
                  <Button
                    variant="contained"
                    sx={{
                      color: "white",
                      border: "1px solid #006D34",
                      backgroundColor: "#006D34",
                    }}
                  >
                    Instructor SignUp
                  </Button>
                </NavLink>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </div>
    </>
  );
};
export default ResponsiveAppBar;
