// TableComponentWithPagination.jsx
import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  styled,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getData, postData } from "../../services/Request";
import Swal from "sweetalert2";

const rowsPerPageOptions = [10, 20, 30];
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 20, // Set your desired font size
  backgroundColor: "#61ED66", // Set your desired background color
  color: theme.palette.common.white, // Set your desired text color
}));

const InstructorList = () => {
  const nav = useNavigate();
  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  useEffect(() => {
    const user = localStorage.getItem("user");
    const data = JSON.parse(user);

    getData("allTeachers").then((response) => {
      setUserData(response.users);
    });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const slicedData = userData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleStatusActive = (id) => {
    Swal.fire({
      title: "Do you want to active this instructor?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Saved!", "", "success");
        postData("updateTeacher", {
          id: id,
          active: true,
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const handleStatusInactive = (id) => {
    Swal.fire({
      title: "Do you want to inactive this instructor?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Saved!", "", "success");
        postData("updateTeacher", {
          id: id,
          active: false,
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  return (
    <>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {slicedData.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.firstname + " " + row.lastname}</TableCell>
                  <TableCell>{row.email}</TableCell>

                  {row.status === 0 ? (
                    <TableCell style={{ color: "red", fontWeight: 700 }}>
                      Inactive
                    </TableCell>
                  ) : (
                    <TableCell style={{ color: "#61ED66", fontWeight: 700 }}>
                      Active
                    </TableCell>
                  )}
                  <TableCell>{row.usertype}</TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleStatusActive(row.id)}
                      >
                        Active
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleStatusInactive(row.id)}
                      >
                        Inactive
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={userData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default InstructorList;
