import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Logo from "../../../assets/Header/logo2.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const navLinks = [
  { text: "Home", location: "/" },
  { text: "Courses", location: "/courses" },
  { text: "About", location: "/about" },
  { text: "Contact", location: "/contact" },
];
const settings = ["Dashboard", "Logout"];

const UserNavbar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [userInfo, setUserInfo] = React.useState();

  useEffect(() => {
    const user = localStorage.getItem("user");
    const data = JSON.parse(user);

    if (user === "" || user === null) {
      nav("/");
    } else {
      setUserInfo(data.firstname);
    }
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const nav = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("user");
    nav("/");
    window.location.reload();
  };

  const handleMenuItemClick = (setting) => {
    handleCloseUserMenu();

    // Call a different function based on the selected setting
    switch (setting) {
      case "Dashboard":
        nav("/courses");
        break;
      case "Logout":
        handleLogout();
        break;
      default:
    }
  };

  return (
    <AppBar
      position="static"
      sx={{
        bgcolor: "white",
        boxShadow: "none",
        borderBottom: "1px solid #d8d8d8",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              mt: 1,
              display: { xs: "none", md: "flex" },
            }}
          >
            <img alt="logo" src={Logo}></img>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="black"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {navLinks.map((linkInfo, index) => (
                <MenuItem key={index} onClick={handleCloseNavMenu}>
                  <NavLink className="nav-links" to={linkInfo.location}>
                    <div className="link">{linkInfo.text}</div>
                  </NavLink>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              mt: 1,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
            }}
          >
            <img alt="logo" src={Logo} style={{ width: "50%" }}></img>
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "end",
              display: { xs: "none", md: "flex" },
            }}
          >
            {navLinks.map((linkInfo, index) => (
              <Button
                key={index}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: "#171717",
                  display: "block",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
              >
                <NavLink className="nav-links" to={linkInfo.location}>
                  <div className="link">{linkInfo.text}</div>
                </NavLink>
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0, ml: "20px" }}>
            <Tooltip title="Open settings">
              <IconButton sx={{ p: 0 }}>
                <Avatar alt={userInfo} src="/" />
                {userInfo}
                <ExpandMoreIcon
                  sx={{ color: "black" }}
                  onClick={handleOpenUserMenu}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => {
                    handleCloseUserMenu();
                    handleMenuItemClick(setting);
                  }}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default UserNavbar;
