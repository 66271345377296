import Axios from "axios";
const BASEURL = "https://techub.wavepalmapps.com";
export var baseUrl = "https://techub-server-7ae320da450d.herokuapp.com/";
// export var baseUrl = "http://localhost:2001/";
export var imgUrl = "https://nimc-app.herokuapp.com/api";
// export var imgUrl = "http://localhost:3010/api";

export var postData = (route, data) => {
  return new Promise((resolve, reject) => {
    Axios.post(baseUrl + route, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getData = (route, data) => {
  return new Promise((resolve, reject) => {
    fetch(baseUrl + route, {
      method: "GET",
      headers: {},
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.error("error in post api", error);
        reject(error);
      });
  });
};

export var uploadFileService = (file) => {
  return new Promise((resolve, reject) => {
    try {
      const formData = new FormData();
      formData.append("photo", file);
      Axios.post(imgUrl + "/image-upload", formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const sendEmail = (URL, data) => {
  return new Promise((resolve, reject) => {
    fetch(BASEURL + URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.error("Error:", error);
        reject(error);
      });
  });
};
