import React from "react";
import { useNavigate } from "react-router-dom";
import "./../styles/Section1.css";
import SP from "../assets/HomePage/spark.png";
import Frame from "../assets/HomePage/Frame1.jpg";

const Section1 = () => {
  const nav = useNavigate();
  const handleClickButton = () => {
    nav("/usersignup");
  };
  return (
    <>
      <div className="flex-container">
        <div className="flex-item s1">Welcome to Wavepalm Techub</div>
        <div className="flex-item2 s2">
          Learning at your <span className="s2-2">Pace</span>
          <span className="s2-3">
            <img src={SP} style={{ width: "8%" }} className="s2-4"></img>
          </span>
        </div>
        <div className="flex-item s3">
          Get rid of the routine feeling from your learning <br />
          process while adding fun to it.
        </div>
        <div className="flex-item ">
          <button className="s4" onClick={handleClickButton}>
            Start Learning
          </button>
        </div>
      </div>
      <div className="box ">
        <div className="image-book"></div>
        <div className="image-frame">
          <img src={Frame} className="image-frame"></img>
        </div>
      </div>
    </>
  );
};

export default Section1;
