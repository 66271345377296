import React from "react";
import "./../styles/Footer.css";
import Logo from "../assets/Footer/logo.png";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="f-logo">
          <div>
            <img src={Logo} alt="logo"></img>
          </div>
        </div>
        <div className="f-links">
          <div className="link">Courses</div>
          <div className="link">About</div>
          <div className="link">Contact</div>
          <div className="link">Privacy policy</div>
        </div>
      </div>
      <div className="copyright">
        <div className="copyright-item">
          © 2023, Wavepalm Techub. All right reserved.
        </div>
      </div>
    </>
  );
};

export default Footer;
